import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/sftsk/Sites/boonconcom/src/components/podcasts-layout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`Intro`}</h3>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/saftsaak"
      }}>{`@saftsaak`}</a>{` – `}<a parentName="p" {...{
        "href": "https://twitter.com/metzelfetz"
      }}>{`@metzelfetz`}</a></p>
    <h3>{`Tobi gets trolled in a good way by the doctor`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt0120201/?ref_=nv_sr_1"
      }}>{`Starship Troopers`}</a>{` – `}<a parentName="p" {...{
        "href": "https://www.wikiwand.com/en/Magnetic_resonance_imaging"
      }}>{`MRI not MRT`}</a>{` – `}<a parentName="p" {...{
        "href": "http://www.lasiksurgerynews.com/news/flex.shtml"
      }}>{`SMILE`}</a>{` – `}<a parentName="p" {...{
        "href": "https://www.wikiwand.com/en/Astigmatism"
      }}>{`Astigmatism`}</a>{` – `}<a parentName="p" {...{
        "href": "http://www.silmaasema.fi/"
      }}>{`Silmäasema`}</a>{` – `}<a parentName="p" {...{
        "href": "https://www.wikiwand.com/en/Hyperopia"
      }}>{`Hyperopia`}</a>{` – `}<a parentName="p" {...{
        "href": "https://www.wikiwand.com/en/Cataract"
      }}>{`"Grey star" -> Cataract`}</a></p>
    <h3>{`AWS Summit Stockholm: Age of Ultron`}</h3>
    <p><a parentName="p" {...{
        "href": "https://aws.amazon.com/summits/stockholm/faqs/"
      }}>{`AWS Summit Stockholm 2015`}</a>{` – `}<a parentName="p" {...{
        "href": "https://tictail.com/"
      }}>{`Tictail`}</a>{` – `}<a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt2395427/?ref_=nv_sr_1"
      }}>{`Avengers: Age of Ultron`}</a>{` – `}<a parentName="p" {...{
        "href": "http://aws.amazon.com/s3/"
      }}>{`Amazon S3`}</a>{` – `}<a parentName="p" {...{
        "href": "http://aws.amazon.com/efs/"
      }}>{`Amazon EFS`}</a></p>
    <h3>{`Haggling and bullying in Italy, Sweden and Poland`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www.torggler.com/en"
      }}>{`Torggler`}</a>{` – `}<a parentName="p" {...{
        "href": "https://www.mm.dk/scandinavian-management-model-makes-good-bottom-lines"
      }}>{`Nordic Business Culture`}</a>{` – `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=3n3LL338aGA"
      }}>{`Haggling`}</a>{` – `}<a parentName="p" {...{
        "href": "https://www.wikiwand.com/en/Partitions_of_Poland"
      }}>{`Partitions of Poland`}</a>{` – `}<a parentName="p" {...{
        "href": "http://images-cdn.9gag.com/photo/anXXB0V_700b.jpg"
      }}>{`Polish grammar`}</a>{` – `}<a parentName="p" {...{
        "href": "http://www.all-languages.org.uk/news/features/speaking_your_mind_links_between_languages_and_other_skills"
      }}>{`Languages and skills`}</a>{` – `}<a parentName="p" {...{
        "href": "https://www.wikiwand.com/en/Philosophy_of_language"
      }}>{`Language philosophy`}</a></p>
    <h3>{`Podlove and the high noble art of podcasting`}</h3>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/hashtag/ppw15a"
      }}>{`#ppw15a`}</a>{` – `}<a parentName="p" {...{
        "href": "https://sendegate.de/"
      }}>{`Sendegate`}</a>{` – `}<a parentName="p" {...{
        "href": "http://publisher.podlove.org/"
      }}>{`Podlove Publisher`}</a>{` – `}<a parentName="p" {...{
        "href": "http://www.robgreenlee.com/2013/01/01/podcasting-vs-youtube/"
      }}>{`Podcasting vs YouTube`}</a>{` – `}<a parentName="p" {...{
        "href": "http://qnz.tumblr.com/post/79571568591/the-german-model-of-podcasting"
      }}>{`German Podcasting`}</a>{` – `}<a parentName="p" {...{
        "href": "http://www.howtopodcasttutorial.com/27-writing-podcast-show-notes.htm"
      }}>{`Shownotes`}</a>{` – `}<a parentName="p" {...{
        "href": "http://podlove.org/podlove-web-player/"
      }}>{`Podlove Webplayer`}</a>{` – Podcatchers: `}<a parentName="p" {...{
        "href": "http://castro.fm/"
      }}>{`Castro`}</a>{`, `}<a parentName="p" {...{
        "href": "https://play.pocketcasts.com/"
      }}>{`Pocket Casts`}</a>{`, `}<a parentName="p" {...{
        "href": "https://itunes.apple.com/us/app/instacast-5-podcast-client/id577056377"
      }}>{`Instacast`}</a></p>
    <h3>{`Sniffing garbage bins, Kellogg's and phone numbers at the re:publica`}</h3>
    <p><a parentName="p" {...{
        "href": "https://re-publica.de/"
      }}>{`re:publica`}</a>{` – `}<a parentName="p" {...{
        "href": "https://twitter.com/hashtag/rp15"
      }}>{`#rp15`}</a>{` – `}<a parentName="p" {...{
        "href": "https://re-publica.de/en/session/beyond-camera-panopticon"
      }}>{`Aral Balkan`}</a>{` – `}<a parentName="p" {...{
        "href": "https://twitter.com/aral"
      }}>{`@aral`}</a>{` – `}<a parentName="p" {...{
        "href": "http://www.thewire.com/politics/2013/06/metadata-nsa-analysis/66171/"
      }}>{`Metadata tracking`}</a>{` – `}<a parentName="p" {...{
        "href": "https://re-publica.de/en/session/our-online-future-worth-sacrificing-our-privacy-and-security"
      }}>{`Mikko Hypponen`}</a>{` – `}<a parentName="p" {...{
        "href": "https://twitter.com/mikko"
      }}>{`@mikko`}</a>{` – `}<a parentName="p" {...{
        "href": "http://www.theguardian.com/news/datablog/2014/apr/22/how-much-is-personal-data-worth"
      }}>{`Value of personal data`}</a>{` – `}<a parentName="p" {...{
        "href": "http://www.bloomberg.com/news/articles/2013-08-12/snooping-garbage-bins-in-city-of-london-ordered-to-be-disabled"
      }}>{`Snooping Garbage Bins`}</a></p>
    <h3>{`The dancing Mickey Mouse on Apple's little helper`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www.apple.com/watch/"
      }}>{`Apple Watch`}</a>{` – `}<a parentName="p" {...{
        "href": "https://itunes.apple.com/tt/app/re-publica-rp14/id869014061"
      }}>{`re:publica app`}</a>{` – `}<a parentName="p" {...{
        "href": "https://www.google.fi/search?q=WAP+screenshot"
      }}>{`WAP pages`}</a>{` – `}<a parentName="p" {...{
        "href": "http://techcrunch.com/2015/05/10/an-apple-watch-in-middle-america/"
      }}>{`Social effects of smart watches`}</a></p>
    <h3>{`HBD EU and why expats aren't immigrants`}</h3>
    <p><a parentName="p" {...{
        "href": "https://www.wikiwand.com/en/Europe_Day"
      }}>{`EU Birthday`}</a>{` – `}<a parentName="p" {...{
        "href": "http://www.telegraph.co.uk/news/general-election-2015/11584325/full-results-map-uk-2015.html"
      }}>{`UK election`}</a>{` – `}<a parentName="p" {...{
        "href": "https://euobserver.com/opinion/128369"
      }}>{`EU needs Britain`}</a>{` – `}<a parentName="p" {...{
        "href": "http://www.bbc.co.uk/programmes/p02k61zh"
      }}>{`EU Mediterranean`}</a>{` – `}<a parentName="p" {...{
        "href": "http://www.huffingtonpost.com/joseph-v-micallef/reflections-on-the-medite_b_7120708.html"
      }}>{`Mediterranean refugee streams`}</a>{` – `}<a parentName="p" {...{
        "href": "http://www.helsinkitimes.fi/finland/finland-news/politics/13348-sipilae-summons-finns-party-national-coalition-for-coalition-talks.html"
      }}>{`Finnish election`}</a>{` – `}<a parentName="p" {...{
        "href": "http://www.theguardian.com/global-development-professionals-network/2015/mar/13/white-people-expats-immigrants-migration?CMP=share_btn_fb"
      }}>{`Expats vs immigrant`}</a>{`s – `}<a parentName="p" {...{
        "href": "https://euobserver.com/"
      }}>{`EUobserver`}</a></p>
    <h3>{`Outro`}</h3>
    <p><a parentName="p" {...{
        "href": "http://booncon.com/podcast"
      }}>{`booncon.com/podcast`}</a>{` – `}<a parentName="p" {...{
        "href": "https://twitter.com/booncon"
      }}>{`@booncon`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      